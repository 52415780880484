.flex {
  display: flex;
}

.w100 {
  width: 100%;
}

.flexCol {
  @extend .flex;
  flex-direction: column;
}

.alignItemsCenter {
  align-items: center;
}

.justContentCenter {
  justify-content: center;
}

.justContentSpaceBtw {
  justify-content: space-between;
}

.flexAlignCol {
  @extend .flexCol;
  @extend .alignItemsCenter;
}

.section {
  margin-top: 5rem;
  padding: 2rem 1rem 4rem;

  .slider {
    display: none;
  }

  .header {
    @extend .w100;
    @extend .flexAlignCol;

    .title {
      font-weight: 600;
      color: var(--black);
      margin-bottom: 1rem;
    }
  }

  .content {
    display: grid;
    grid-template-columns: repeat(2, minmax(150px, 250px));
    gap: 1rem;
    justify-content: center;

    .contact,
    a.contact {
      @extend .flexAlignCol;
      border: 1px solid #e5e5e5;
      border-radius: 10px;
      gap: 0.5rem;
      padding: 1rem;
  
      .iconDiv {
        background-color: var(--lilac);
  
        @extend .flexAlignCol;
        @extend .justContentCenter;
  
        height: 40px;
        width: 40px;
        border-radius: 50%;
  
        .icon {
          font-size: 1.25rem;
          color: black;
        }
      }
  
      .div {
        text-align: center;
  
        .title {
          margin-bottom: 0.25rem;
          font-size: 0.8rem;
          font-weight: 700;
          color: black;
          cursor: pointer;
        }
  
        .text {
          font-size: 0.75rem;
          color: black;
        }

        .textb {
          @extend .text;
          max-width: 80%;
          margin: 0 auto;
        }

        @media screen and (min-width: 768px) {
          .title {
            font-size: 1rem;
          }

          .text {
            font-size: 15px;
          }
        }
      }
    }
  }

  @media screen and (min-width: 768px) {
    padding: 0;
    margin-top: 5rem;

    .slider {
      display: flex;
      padding: 1rem;
      background-color: #f5f5f5;
      width: 100%;
      justify-content: center;
      font-weight: 600;
      font-size: 14px;
    }

    .header {
      padding: 3rem 5% 0;
    }

    .content {
      padding: 1rem 5% 5%;
    }
  }
}
