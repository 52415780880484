/* Declaring some common styles */
.center {
  text-align: center;
}

.flexCol {
  display: flex;
  flex-direction: column;
}

.flex {
  display: flex;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.alignItemsCenter {
  align-items: center;
}

.justContentCenter {
  justify-content: center;
}

.justContentSpaceBtw {
  justify-content: space-between;
}

.container {
  width: 90%;
  margin: 90px auto 0;
  @extend .relative;
  height: 40vh;
  @extend .flexCol;
  @extend .alignItemsCenter;
  color: #fff;
  gap: 1rem;
  background-color: rgba(0, 0, 0, 0.1);

  .hide {
    display: none;
  }

  .bgImg {
    position: absolute;
    min-width: 100%;
    height: 40vh;
    object-fit: unset;
  }

  .title {
    font-size: 22px;
    font-weight: 700;
    height: 100px;
    max-width: 90%;
    @extend .center;
    margin-top: 5rem;
    z-index: 10;
  }

  .button {
    border: none;
    background: var(--lilac);
    text-transform: uppercase;
    width: fit-content;
    font-size: 12px;
    font-weight: 500;
    color: var(--black);
    padding: 0.75rem 1rem;
    z-index: 10;
    transition: all 0.2s ease;
    @extend .absolute;
    top: 65%;

    &:hover {
      background-color: var(--shadow);
      transform: scale(1.03);
      color: var(--light);
    }

    &:focus {
      transform: scale(0.9);
    }
  }

  @media screen and (min-width: 768px) {
    height: 70vh;
    justify-content: flex-start;

    .bgImg {
      height: 70vh;
      object-fit: cover;
    }

    .title {
      margin-top: 13vh;
      font-size: 5vw;
      max-width:70%;
    }

    .button {
      margin-top: 3rem;
      font-size: 17px;
      padding: 1rem;
    }
  }

  @media screen and (min-width: 956px) {
    .title {
      font-size: 3rem;
    }

    .button {
      margin-top: 2rem;
      font-size: 1rem;
    }
  }

  @media screen and (min-width: 1200px) {
    height: 80vh;
    margin-top: 7rem;

    .bgImg {
      height: 80vh;
      object-fit: auto;
    }
  }
}
