/* Declaring some common styles */
.w100 {
  width: 100%;
}

.flexCol {
  display: flex;
  flex-direction: column;
}

.flex {
  display: flex;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.alignItemsCenter {
  align-items: center;
}

.flexAlignCol {
  @extend .flexCol;
  @extend .alignItemsCenter;
}

.justContentCenter {
  justify-content: center;
}

.justContentSpaceBtw {
  justify-content: space-between;
}

.section {
  margin-top: 4rem;
  padding: 3rem 1rem;

  .header {
    @extend .w100;
    @extend .flexAlignCol;

    .title {
      display: none;
    }

    @media screen and (min-width: 956px) {
      align-items: start;

      .title {
        display: block;
        font-weight: 700;
        font-size: 2rem;
        padding-bottom: 1rem;
      }
    }
  }

  .main {
    @extend .flexCol;
    @extend .alignItemsCenter;

    .title {
      font-size: 1.5rem;
      font-weight: 700;
      margin-bottom: 0.5rem;
      color: var(--shadow);
    }

    .info {
      .content {
        width: 100%;
        .features {
          border: 1px solid var(--black);
          margin: 1rem auto;
          padding: 1rem;
          text-transform: capitalize;
          width: 80%;
          max-width: 370px;
          background-color: #f2f2f2;
        }
      }

      .description {
        @extend .flexCol;
        @extend .alignItemsCenter;

        .text {
          text-align: justify;
          margin: 0.5rem 0 1rem;
        }
      }
    }
  }

  .gallery {
    @extend .flexAlignCol;
    gap: 2rem;

    .image {
      width: 100%;
    }
  }

  .view {
    @extend .flex;
    @extend .justContentSpaceBtw;
    margin-top: 2rem;

    .btn {
      background-color: transparent;
      border: none;
      color: var(--lilac);
      font-weight: 500;
      font-size: 0.85rem;
      @extend .flex;
      @extend .alignItemsCenter;
      gap: 0.15rem;
      margin-top: 0.5rem;
      transition: all 0.3s cubic-bezier(0.6, -0.28, 0.735, 0.045);

      &:hover {
        color: var(--shadow);
      }

      &:active {
        transform: scale(0.9);
      }

      .ProjectIcon {
        display: block;
      }
    }
  }

  @media screen and (min-width: 768px) {
    padding: 3rem 5%;

    .main {
      flex-direction: row;
      align-items: start;
      gap: 2rem;
      margin-bottom: 2rem;

      .img {
        max-width: 60%;
        align-self: stretch;
      }

      .info {
        .content {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;

          .title {
            margin-top: 0;
            font-size: 1.5rem;
          }

          .features{
            margin: 1rem 0;
          }
        }

        .description {
          align-items: flex-start;
        }
      }

      @media screen and (min-width: 1400px) {
        justify-content: unset;

        .img {
          min-width: 60%;
          flex-grow: 1;
        }
      }
    }
    
    .gallery {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 1rem;
      row-gap: 2rem;
    }
  }

  @media screen and (min-width: 1400px) {
    .view {
      margin-top: 1rem;

      .btn{
        font-size: 1rem;
      }
    }
  }
}
