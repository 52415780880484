.scroll {
  position: fixed;
  cursor: pointer;
  top: 87vh;
  font-size: 2.5rem;
  left: 85%;
  color: var(--lilac);
  transition: all 0.3s ease;
  z-index: 800000000;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

  &:hover {
    color: var(--shadow);
    transform: scale(1.1);
  }

  &:active {
    color: var(--light);
    transform: scale(1.06);
  }

  @media screen and (min-width: 768px) {
    font-size: 3rem;
    left: 90%;
  }

  @media screen and (min-width:956px) {
    left: 93%;
    color: var(--light);
  }
}
