.subGroup {
  display: none;

  @media screen and (min-width: 1024px) {
    display: flex;  
  }
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.dropzone {
  border: 2px dashed #979797;
  border-radius: 4px;
  padding: 20px;
  max-width: 300px;
  text-align: center;
  background-color: #f2f2f2;
  cursor: pointer;
  margin: 1rem auto;

  &:hover {
    background-color: #f0f0f0;
  }

  &.active {
    box-shadow: inset 1px 1px 3px #ccc;
  }

  p {
    font-size: 0.95rem;
    color: #979797;
  }

  @media screen and (min-width: 1024px) {
    margin: 1rem 0;
  }
}

.preview {
  margin-top: 20px;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;

  @media screen and (min-width: 1024px) {
    justify-content: start;
  }
}

.img {
  max-width: 40%;
  height: auto;
}
