.flexCol {
  display: flex;
  flex-direction: column;
}

/************/  
  
.container {
  padding: 1.5rem;
  margin: 7rem 0;
  min-height: 60vh;

  .title {
    text-transform: uppercase;
    font-size: 1rem;
    margin: 1rem 0 1.3rem;
  }

  .subtitle {
    text-align: justify;
    font-size: 1rem;
  }

  .list {
    @extend .flexCol;
    gap: 1rem;
    margin: 1.5rem 0;

    .item {
      list-style-type: disc;
      margin-left: 1rem;

      span {
        font-weight: 600;
      }

      a {
        color: #4b8df0;
      }
    }
  }

  @media screen and (min-width: 956px) {
    margin-top: 13rem;
    padding: 1rem 5% 2rem;
    max-width: 80%;

    .title {
      font-size: 1.5rem;
    }
  }
}