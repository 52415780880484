/* Declaring some common styles */
.auto {
  margin: 0 auto;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.flexCol {
  display: flex;
  flex-direction: column;
}

.flexRow {
  display: flex;
  flex-direction: row;
}

.w100 {
  width: 100%;
}

.w90 {
  width: 90%;
}

.w80 {
  width: 80%;
}

.flex {
  display: flex;
}

.alignItemsCenter {
  align-items: center;
}

.justContentCenter {
  justify-content: center;
}

.flexAlignCol {
  @extend .flexCol;
  @extend .alignItemsCenter;
}

/***********   */

/* Interior */

/***********   */
.interior {
  @extend .w100;
  @extend .auto;
  padding: 2rem 1rem 3rem;
  margin-top: 5rem;

  .intro {
    @extend .flexAlignCol;

    text-align: center;

    .formTitle {
      font-size: 1.5rem;
      font-weight: 700;
      margin-bottom: 1rem;
    }

    .formPara {
      text-align: justify
    }
  }

  .form {
    @extend .flexAlignCol;
    @extend .w100;
    margin-top: 2rem;

    .subGroup {
      @extend .flexCol;
      width: 100%;

      .formGroup {
        @extend .flexCol;
        @extend .w100;
        margin-bottom: 1rem;
        gap: 0.65rem;

        .formHead {
          font-size: 1rem;
          font-weight: 700;
          margin-top: 0.5rem;
          margin-bottom: 0.5rem;
        }

        .formField {
          padding: 0.75rem;
          border: 1px solid #979797;
          background-color: #f9f9f9;
          width: 100%;

          &::placeholder {
            color: #979797;
          }

          &:focus {
            outline: 1px solid var(--light);
          }
        }

        .formLabel {
          color: #979797;
          margin-top: 1rem;
        }

        textarea {
          padding: 1rem;
          font-size: 14px;
          border: 1px solid #979797;
          background-color: #f9f9f9;
          height: 8rem;
          overflow: auto;
          width: 100%;

          &::placeholder {
            color: #979797;
          }

          &:focus {
            outline: 1px solid var(--light);
          }
        }

        .formHeadA {
          font-size: 1rem;
          font-weight: 700;
          margin-top: 0.5rem;
          margin-bottom: 0.5rem;
          text-align: center;
        }

        .btnB {
          display: none;
        }
      }
    }

    .btn {
      padding: 1rem;
      font-size: 15px;
      text-transform: uppercase;
      background-color: var(--lilac);
      color: #fff;
      border: none;
      cursor: pointer;
      max-width: fit-content;
      transition: background-color 0.2s;

      &:hover {
        background-color: var(--shadow);
      }

      &:active {
        outline: none;
        transform: scale3d(1.05, 1.05, 1.05);
      } 
    }
  }

  @media screen and (min-width: 768px) {
    padding: 2rem 5% 3rem;

    .intro {
  
      .formPara {
        font-size: 1rem;
      }
    }
    .form {
      .subGroup {
        .formGroup {
          .formField {
            font-size: 1rem;
          }
        }
      }

      .btn {
        padding: 1.5rem;
        font-size: 1rem;
      }
    } 
  }

  @media screen and (min-width: 1024px) {
    padding-bottom: 8rem;
    .form {
      .subGroup {
        flex-direction: row;
        gap: 5rem;
        justify-content: space-between;
        width: 100%;

        .formGroup {
          width: 40%;

          .formGroup {
            width: 100%;
          }

          .formHeadA {
            text-align: left;
          }

          :nth-of-type(even) {
            justify-self: flex-end;
          }

          .btnB {
            display: flex;
            padding: 1rem;
            font-size: 1rem;
            text-transform: uppercase;
            background-color: var(--lilac);
            color: #fff;
            border: none;
            cursor: pointer;
            max-width: fit-content;
            transition: background-color 0.2s;

            &:hover {
              background-color: var(--shadow);
            }

            &:active {
              outline: none;
              transform: scale3d(1.05, 1.05, 1.05);
            } 
          }
        }
      }

      .btn {
        display: none;
      }
    }
  }
}

.container {
  @extend .relative;
  @extend .flexAlignCol;
  @extend .justContentCenter;
  text-align: center;
  @extend .w100;
  padding: 2rem 1rem 3rem;
  margin-top: 8rem;

  .lottie {
    @extend .absolute;
  }

  .content {
    @extend .flexAlignCol;
    gap: 1rem;

    .image {
      width: 20%;
      margin: 2rem 0;

      @media screen and (min-width: 600px) {
        width: 10%;  
      }
    }
  }

  .btn {
    display: flex;
    padding: 1rem;
    font-size: 1rem;
    text-transform: uppercase;
    background-color: var(--lilac);
    color: #fff;
    border: none;
    cursor: pointer;
    max-width: fit-content;
    margin: 3rem 0 1rem;
    transition: background-color 0.2s;

    &:hover {
      background-color: var(--shadow);
    }

    &:active {
      outline: none;
      transform: scale3d(1.05, 1.05, 1.05);
    } 
  }
}
