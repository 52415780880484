/* Declaring some common styles */
.flexCol {
  display: flex;
  flex-direction: column;
}

.w100 {
  width: 100%;
}

.alignItemsCenter {
  align-items: center;
}

.justContentCenter {
  justify-content: center;
}

.flexAlignCol {
  @extend .flexCol;
  @extend .alignItemsCenter;
}
/* **********   */

.container {
  padding: 0 1rem;

  .header {
    @extend .w100;
    @extend .flexAlignCol;

    .title {
      font-weight: 700;
    }

    .underline {
      height: 3px;
      width: 20%;
      margin-top: 0.5rem;
      background: var(--light);
    }

    @media screen and (min-width: 768px) {
      .title {
        font-size: 2rem;
      }

      .underline {
        width: 10%;
      }
    }
  }

  .content {
    padding: 1rem 0 0;
    @extend .flexAlignCol;

    .textContent {
      @extend .flexCol;
      gap: 1rem;
    }

    .perksDiv {
      padding: 2rem 0;
      @extend .flexCol;
      gap: 2rem;

      .perk {
        @extend .flexAlignCol;
        border-radius: 20px;
        gap: 0.5rem;
        width: 70%;
        max-width: 400px;
        padding: 1.5rem 1rem;
        margin: 0 auto;
        background: linear-gradient(166.95deg, var(--light) 9.41%, var(--lilac) 93.81%);
        -webkit-box-shadow: 0 19px 14px -4px rgba(160, 32, 240, 1);
        -moz-box-shadow: 0 9px 14px -4px rgba(160, 32, 240, 1);
        box-shadow: 0 9px 14px -4px rgba(160, 32, 240, 1);

        .icon {
          font-size: 2.5rem;
          color: var(--black);
        }

        .div {
          text-align: center;

          .heading {
            color: var(--black);
            margin-bottom: 0.5rem;
            font-size: 1rem;
          }

          .text {
            font-size: 14px;
            line-height: 1.4;
            text-align: justify;
          }
        }
      }

      @media screen and (min-width: 768px) {
        width: 100%;
        display: grid;
        justify-content: center;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 0;
      }
    }
  }

  @media screen and (min-width: 960px) {
    padding: 0% 5%;

    .content {
      .perksDiv {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        gap: 2rem;

        .perk {
          padding: 1.5rem 1.5rem;
          max-width: 278px;
          .div {
            text-align: center;

            .heading {
              font-size: 1rem;
            }
  
            .text {
              font-size: 1rem;
              line-height: 1.4;
              max-width: 250px;
              margin: 0 auto;
            }
          }
        }
      }
    }
  }
}
