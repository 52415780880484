/* Declaring some common styles */
.flexCol {
  display: flex;
  flex-direction: column;
}

.alignItemsCenter {
  align-items: center;
}

.flexAlignCol {
  @extend .flexCol;
  @extend .alignItemsCenter;
}

/***********   */
.stats {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  color: var(--white);
  padding: 2rem 1rem;

  .stat {
    @extend .flexAlignCol;
    padding: 1rem;
    color: var(--black);

    .number {
      font-size: 1.5rem;
      font-weight: 700;
    }

    .text {
      text-align: center;
      margin-top: 0.5rem;
    }

    .lilac {
      color: var(--lilac);
    }
  }

  @media screen and (min-width: 956px) {
    padding: 3rem 10%;
    gap: 1rem;

    .stat {
      .number {
        font-size: 2.5rem;
      }
    }
  }
}
