/* Declaring some common styles */
.auto {
  margin: 0 auto;
}

.flexCol {
  display: flex;
  flex-direction: column;
}

.w100 {
  width: 100%;
}

.alignItemsCenter {
  align-items: center;
}

.flexAlignCol {
  @extend .flexCol;
  @extend .alignItemsCenter;
}

/***********   */

.container {
  margin-top: 72px;
  padding: 3rem 5%;
  .header {
    @extend .w100;
    @extend .flexAlignCol;

    .title {
      font-weight: 600;
      color: var(--black);
    }

    @media screen and (min-width: 956px) {
      align-items: start;

      .title {
        font-weight: 700;
        font-size: 2rem;
      }
    }
  }

  .content {
    padding-top: 1rem;
    @extend .flexAlignCol;
    text-align: justify;
    gap: 1rem;

    .textContent {
      @extend .flexAlignCol;
      gap: 1rem;

      .textDiv {
        @extend .flexAlignCol;
        gap: 1rem;

        .subtitle {
          padding-bottom: 0.5rem;
          font-weight: 700;
          text-align: center;
        }
      }
    }

    .aboutImg {
      margin: 1rem 0 0;
    }

    .bold {
      font-weight: 700;
    }
  }

  .spliter {
    display: none;
  }

  @media screen and (min-width: 768px) {
    .content {
      padding-bottom: 0;

      .aboutImg {
        width: 70%;
        margin: 0 auto;
        align-self: stretch;
      }

      .textContent {
        // align-items: flex-start;

        .subtitle {
          margin-top: 0;
        }
      }
    }
  }

  @media screen and (min-width: 956px) {
    .content {
      .textContent {
        flex-direction: row;
        gap: 2rem;

        .lastText {
          display: none;
        }

        .textDiv {
          .subtitle {
            text-align: left;
            font-size: 2.5rem;
          }
        }
      }
    }

    .spliter {
      padding-top: 2rem;
      text-align: justify;
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .bold {
        font-weight: 700;
      }
    }
  }

  @media screen and (min-width: 1130px) {
    .spliter {
      display: none;
    }

    .content {
      .textContent {
        .lastText {
          display: block;
        }
      }
    }
  }

  .missionContainer {
    padding: 0 0 3rem;

    .content {
      @extend .flexAlignCol;

      .textContent {
        @extend .flexAlignCol;
        gap: 1rem;

        .subtitle {
          font-weight: 700;
          text-transform: uppercase;
          margin-top: 1rem;
        }

        .text {
          text-align: justify;
        }
      }

      .list {
        @extend .flexAlignCol;
        justify-content: center;

        gap: 2rem;

        .listItem {
          @extend .flexAlignCol;
          gap: 1rem;

          .badge {
            width: 50%;
          }

          .listText {
            text-align: center;

            .text {
              max-width: 200px;
              text-align: center;
            }

            .heading {
              max-width: 200px;
              margin-top: 0.5rem;
              text-transform: uppercase;
            }
          }
        }
      }

      .aboutImg {
        // margin-top: 2rem;
        width: 60%;
        margin-bottom: 1rem;
      }
    }

    @media screen and (min-width: 768px) {
      padding-bottom: 5%;

      .header {
        .title {
          font-size: 2rem;
        }
      }

      .content {
        gap: 2rem;

        .textContent {
          gap: 1rem;

          .list {
            flex-direction: row;
            gap: 1rem;
    
            .listItem {
              gap: 1rem;
    
              .badge {
                width: 40%;
              }
    
              .listText {
                text-align: center;
    
                .text {
                  max-width: 300px;
                  margin: 0 auto;
                  text-align: center;
                }
    
                .heading {
                  max-width: 400px;
                  margin-top: 0.5rem;
                }
              }
            }
          }
        }

        .aboutImg {
          margin-top: 0;
          width: 30%;
        }
      }
    }

    @media screen and (min-width: 956px) {
      .content {
        .textContent {
          .text {
            text-align: center;
            max-width: 60%;
          }

          .list {
            flex-direction: row;
            align-items: start;

            .listItem {
              gap: 1rem;

              .badge {
                width: 50%;
              }

              .listText {
                text-align: center;

                .text {
                  max-width: 200px;
                  // font-size: 1rem;
                }

                .heading {
                  max-width: 200px;
                  margin-top: 0.5rem;
                  font-size: 14px;
                }
              }
            }
          }
        }
      }

      .aboutImg{
        max-width: 40%;
      }
    }
  }
}
