@import url('https://fonts.cdnfonts.com/css/tt-norms-pro');

:root {
  /* Colour */
  --lilac: rgb(212, 162, 246);
  --white: rgb(255, 255, 255);
  --light: rgb(234, 207, 252);
  --shadow: rgb(160, 31, 240);
  --black: #000;

  /* Fonts */
  --tt-norms: 'TT Norms Pro', sans-serif;
}

/* Reset CSS */
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
}

li {
  list-style: none;
}

a,
button {
  cursor: pointer;
  font-family: var(--tt-norms);
  border-radius: 4px;
}

img {
  max-width: 100%;
  height: auto;
  object-fit: cover;
}

body {
  font-size: 14px;
  line-height: 1.4;
  font-family:
    var(--tt-norms),
    -apple-system,
    BlinkMacSystemFont,
    'Roboto',
    'Fira Sans',
    'Droid Sans',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @media screen and (min-width: 768px) {
    font-size: 16px;
  }
}

.react-multi-carousel-dot button {
  border: var(--shadow) !important;
}

.react-multi-carousel-dot--active button {
  background: var(--lilac) !important;
}

.react-multiple-carousel__arrow {
  background: rgba(234, 207, 252, 0.5) !important;
}

.react-multiple-carousel__arrow:hover {
  background: var(--lilac) !important;
}

.react-multiple-carousel__arrow--right {
  right: calc(3% + 1px);
}

.react-multiple-carousel__arrow--left {
  left: calc(3% + 1px);
}

.react-international-phone-input-container {
  display: flex;
  font-size: 14px;
  height: 35px !important;
  border-radius: 0 !important;
}

.react-international-phone-country-selector-button__button-content {
  display: flex;
  font-size: 14px;
  height: 41px !important;
  border-radius: 0 !important;
}

.react-international-phone-country-selector-button__button {
  border-radius: 0 !important;
  padding: 0.75rem !important;
}

.react-international-phone-input,
.react-international-phone-input-container .react-international-phone-input {
  border: 1px solid #979797 !important;
  background-color: #f9f9f9 !important;
  height: 41px !important;
  padding: 0.75rem !important;
  font-size: 14px !important;
  width: 100%;
  border-radius: 0 !important;
}

.react-international-phone-country-selector,
.react-international-phone-country-selector-button {
  border-color: #979797 !important;
  height: 41px !important;
  border-radius: 0 !important;
}

.react-international-phone-input:focus {
  outline: 1px solid var(--light) !important;
}

.css-1im77uy-control {
  height: 41px !important;
  border-color: #979797 !important;
}

@media screen and (min-width: 768px) {
  .css-1im77uy-control {
    font-size: 1rem;
  }
}

.carousel {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;
  padding: 2rem 0 0;
}

.carousel.desktop {
  display: none;
}

.carousel-images {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 100%;
  height: 250px;
  object-fit: cover;
  position: relative;
}

@media screen and (min-width: 959px) {
  .carousel.mobile {
    display: none;
  }

  .carousel.desktop {
    display: flex;
  }

  .carousel-images {
    height: 300px;
  }
}

.image-container {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}

.download {
  position: absolute;
  top: 18%;
  left: 70%;
  display: none;
  transform: translate(-50%, -50%);
  background-color: var(--light);
  color: black;
  justify-content: center;
  gap: 0.5rem;
  align-items: center;
  border: none;
  border-radius: 4px;
  padding: 10px;
  cursor: pointer;
  font-size: 14px;
  z-index: 3;
  transition: all 0.3s;
}

.download:hover {
  background-color: var(--shadow);
  color: white;
}

.image-container img {
  max-height: 80%;
  max-width: 80%;
  border-radius: 10px;
}

.center {
  z-index: 10;
}

.center .download {
  display: flex;
}

.side {
  z-index: 1;
}

/* .hidden {
  z-index: 0;
  opacity: 0;
  pointer-events: none;
} */

.abButton {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(214, 170, 219, 0.4);
  color: black;
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
  z-index: 1000;
  transition: all 0.3s;
}

.adButton:active {
  transform: scale(0.9);
}

.prev {
  left: 0;
}

.prev:hover {
  background-color: var(--shadow);
  color: white;
}

.next {
  right: 0;
}

.next:hover {
  background-color: var(--shadow);
  color: white;
}
