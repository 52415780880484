/* Declaring some common styles */
.flexCol {
  display: flex;
  flex-direction: column;
}

.w100 {
  width: 100%;
}

.w90 {
  width: 90%;
}

.none {
  display: none;
}

.flex {
  display: flex;
}

.alignItemsCenter {
  align-items: center;
}

.justContentCenter {
  justify-content: center;
}

.flexAlignCol {
  @extend .flexCol;
  @extend .alignItemsCenter;
}
/***********   */

.container {
  padding: 2rem 1rem 0;

  &.pad {
    margin-top: 72px;
    // padding-bottom: 3rem;
  }

  .header {
    @extend .w100;
    @extend .flexAlignCol;

    .title {
      font-weight: 600;
      color: var(--black);
    }

    .underline {
      height: 3px;
      width: 20%;
      margin-top: 0.5rem;
      background: var(--light);
    }

    @media screen and (min-width: 768px) {
      .underline {
        width: 10%;
      }

      &.start {
        align-items: start;
      }
    }
  }

  @media screen and (min-width: 768px) {
    &.pad {
      padding-bottom: 4rem;
    }
  }

  @media screen and (min-width: 956px) {
    padding-top: 2rem;
  }

  .content {
    padding: 2rem 0;
    @extend .flexAlignCol;
    gap: 1rem;

    .linker {
      @extend .w90;
      max-width: 345px;
      transition: all 0.1s ease-in;
      cursor: pointer;
      border-radius: 20px;
      background: rgba(234, 207, 252, 0.1);

      &:active {
        background: linear-gradient(166.95deg, var(--light) 9.41%, var(--lilac) 93.81%);
        transform: scale(0.9);
      }

      .perk {
        @extend .flexAlignCol;
        .div {
          padding: 0 1rem 2rem;
          text-align: center;
  
          .heading {
            color: var(--black);
            font-size: 1.15rem;
            font-weight: 700;
          }
  
          .text {
            color: black;
          }
        }
  
        &:hover {
          color: var(--white);
          opacity: 0.7;
  
          .heading {
            color: var(--shadow);
          }
  
          .text {
            color: var(--white);
          }
        }
      }
    }

    .perker {
      position: relative;
      width: 100%;

      .img {
        width: 100%;
        object-fit: cover;
      }

      .div {
        text-align: justify;

        .heading {
          text-align: center;
          margin: 0.5rem 0;
          font-size: 1.5rem;
        }

        .btn {
          width: fit-content;
          background-color: var(--lilac);
          color: var(--white);
          padding: 1rem;
          text-transform: uppercase;
          margin: 1rem auto 1.5rem;
          text-align: center;
          display: block;
          transition: all 0.2s ease-in-out;
          font-size: 14px;

          &:hover {
            background: var(--shadow);
            transform: scale(1.01);
          }

          &:focus {
            transform: scale(0.9);
          }
        }
      }

      button {
        border: none;
        position: absolute;
        border-radius: 50%;
        cursor: pointer;
        background: rgba(234, 207, 252, 0.5);
        color: rgba(255, 255, 255, 0.61);
        display: flex;
        align-items: center;
        top: 16%;
        justify-content: center;
        transition: all 0.2s ease;
        height: 43px;
        width: 43px;

        &:hover {
          background: var(--lilac);
          color: white;
        }

        &:active {
          transform: scale(0.95);
        }
      }

      .icon {
        font-size: 35px;
        opacity: 0.5;
        transition: all 0.2s ease;

        &:hover {
          opacity: 1;
        }
      }

      .prevButton {
        left: 3%;
      }

      .nextButton {
        right: 4%;
      }

      @media screen and (min-width: 768px) {
        display: flex;
        justify-content: space-between;
        gap: 2%;

        .icon,
        .img {
          min-width: 55%;
        }

        .div {
          .heading {
            text-align: left;
            margin-top: 0;
          }

          .btn {
            margin: 1.5rem 0 0;
            font-size: 14px;
          }
        }

        button {
          top: 45%;
        }

        .prevButton {
          left: 2.5%;
        }
  
        .nextButton {
          right: calc(36vw + 6.5vw);
        }
      }
    }

    @media screen and (min-width: 768px) {
      flex-direction: row;

      &.flexContent {
        flex-direction: column;
        justify-content: space-between;;
      }

      .linker {
        width: 100%;
        max-width: unset;
      }
    }

    @media screen and (min-width: 956px) {
      .perker {
        display: flex;
        justify-content: space-between;
        gap: 5%;

        .div {
          .heading {
            text-align: left;
            margin-top: 0;
          }

          .text {
            font-size: 1rem;
          }

          .btn {
            margin: 2rem 0 0;
            font-size: 15px;
          }
        }

        .nextButton {

          @media screen and (min-width: 1500px) {
            right: calc(36% + 10vw);
          }
        }
      }
    }
  }

  @media screen and (min-width: 768px) {
    padding: 3rem 5% 0;
    .title {
      font-size: 2rem;
      font-weight: 700;
    }

    .content {
      gap: 4rem;

      .linker {
        min-height: 270px;

        .perk {
          .icon,
          .img {
            width: 100%;
          }

          .div {
            .text {
              font-size: 14px;
            }
  
            .heading {
              font-size: 1.45vw;
            }
          }
        }
      }
    }
  }
}
