/* Declaring some common styles */
.w100 {
  width: 100%;
}

.flexCol {
  display: flex;
  flex-direction: column;
}

.flex {
  display: flex;
}

.alignItemsCenter {
  align-items: center;
}

.flexAlignCol {
  @extend .flexCol;
  @extend .alignItemsCenter;
}

.justContentCenter {
  justify-content: center;
}

.ProjectDiv {
  padding: 0 1rem;
  width: 100%;
  margin: 2rem auto 0;
  text-align: center;

  &.pad {
    margin: 8rem auto;
  }

  .header {
    @extend .w100;
    @extend .flexAlignCol;

    .title {
      font-weight: 600;
      color: var(--black);
      // font-size: 2rem;
    }

    .underline {
      height: 3px;
      width: 20%;
      margin-top: 0.5rem;
      background: var(--light);
    }

    @media screen and (min-width: 768px) {
      .underline {
        width: 10%;
      }  

      .title {
        font-size: 2rem;
      }
    }
  }

  @media screen and (min-width: 956px) {
    .start {
      align-items: start;
    }
    padding-bottom: 2rem;

    .header {
      .title {
        font-size: 2rem;
        font-weight: 700;
      }
    }
  }

  .Projects {
    @extend .flexCol;
    gap: 3rem;
    width: 100%;
    padding: 2rem 0;

    .Project {
      width: 100%;
      @extend .flexAlignCol;
      gap: 1rem;
      margin: 0 auto;
      cursor: pointer;

      .ProjectImg {
        width: 100%;
        // min-height: 358px;
      }

      .ProjectInfo {
        width: 100%;
        @extend .flexAlignCol;
        gap: 0.75rem;

        .ProjectName {
          padding: 0 1rem;
        }

        .ProjectDesc {
          text-align: justify;
        }

        .ProjectLink {
          background-color: transparent;
          border: none;
          color: var(--lilac);
          font-weight: 500;
          @extend .flex;
          @extend .alignItemsCenter;
          gap: 0.15rem;
          margin-top: 0.5rem;
          font-size: 1rem;

          .ProjectIcon {
            display: block;
            margin-top: 0.18rem;
          }
        }
      }

      .ProjectLink {
        cursor: pointer;
      }
    }

    @media screen and (min-width: 768px) {
      padding: 2rem 0;

      .Project {
        .ProjectImg {
          min-height: 50vh;
        }
      }
    }

    @media screen and (min-width: 956px) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 3%;
      margin-bottom: 25%;
      // padding: 2rem 5%;

      .Project {
        .ProjectInfo {
          align-items: start;

          .ProjectName {
            padding: 0;
            font-size: 1.5rem;
          }
        }
      }
    }

    @media screen and (min-width: 2500px) {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      padding: 5% 0;
      row-gap: 2rem;
    }
  }

  .seeMoreBtn {
    background: var(--lilac);
    color: var(--white);
    border: none;
    padding: 0.75rem;
    margin: 2rem auto;
    width: 60%;
    place-self: center;
    transition: all 0.2s ease;

    &:hover {
      background: var(--shadow);
      transform: scale(1.01);
    }

    &:active {
      transform: scale(0.9);
    }

    @media screen and (min-width: 768px) {
      width: 30%;
    }
  }

  @media screen and (min-width: 956px) {
    padding: 0 5% 2rem;
  }
}
