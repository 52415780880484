.flexCol {
  display: flex;
  flex-direction: column;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.w100 {
  width: 100%;
}

.alignItemsCenter {
  align-items: center;
}

.flexAlignCol {
  @extend .flexCol;
  @extend .alignItemsCenter;
}

/***********   */

.container {
  padding: 0 1rem 3rem;

  .header {
    @extend .w100;
    @extend .flexAlignCol;

    .title {
      font-weight: 600;
      color: var(--black);
    }

    .underline {
      height: 3px;
      width: 20%;
      margin-top: 0.5rem;
      background: var(--light);
    }

    @media screen and (min-width: 768px) {
      .underline {
        width: 10%;
      }

      .title {
        font-size: 2rem;
      }
    }
  }

  .reviewCard {
    color: var(--black);
    @extend .relative;
    margin: 2rem 1.5rem;
    max-width: 500px;
    font-size: 0.7rem;
    line-height: 1.4;
    background-color: #fafafa;
    min-height: 264px;
    border: 1px solid var(--light);
    border-radius: 20px;
    -webkit-box-shadow: 0 9px 10px -4px rgba(160, 32, 240, 1);
    -moz-box-shadow: 0 9px 10px -4px rgba(160, 32, 240, 1);
    box-shadow: 0 9px 10px -4px rgba(160, 32, 240, 1);
    padding: 3rem 2rem;

    .reviewText {
      font-size: 14px;
      text-align: justify;
      margin-top: 1.5rem;
    }

    .reviewName {
      font-size: 15px;
      font-weight: 700;
      margin-top: 0.5rem;
      color: var(--shadow);

      .arrow {
        color: var(--lilac);
      }
    }

    .reviewRating {
      @extend .absolute;
      top: 20px;
      left: 9%;
      width: 6%;
    }
  }

  // @media screen and (min-width: 768px) {
  //   padding: 5% 10%;

  //   .title {
  //     font-size: 2rem;
  //   }

  //   .reviewCard {
  //     color: white;
  //     margin: 0 auto;
  //     padding: 1.25rem 1rem;
  //     margin-top: 2rem;

  //     .reviewText {
  //       font-size: 14px;
  //       text-align: center;
  //     }
  //   }
  // }

  @media screen and (min-width: 768px) {
    padding: 0 5% 3rem;
  }
}
