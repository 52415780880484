/* Declaring some common styles */
.flexCol {
  display: flex;
  flex-direction: column;
}

.flex {
  display: flex;
}

.alignItemsCenter {
  align-items: center;
}

.justContentCenter {
  justify-content: center;
}

.justContentSpaceBtw {
  justify-content: space-between;
}

/***********   */

.footer {
  width: 100%;
  color: black;
  background: #f2f2f2;
  padding: 2rem 1rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  .footerContactContent {
    @extend .flex;
    @extend .justContentCenter;
    gap: 1.5rem;

    .footerContactMenu {
      @extend .flexCol;
      gap: 0.7rem;

      .footerContactItem {
        font-weight: 600;
        color: var(--shadow);
        font-size: 15px;
        margin-bottom: 0.5rem;
      }

      .footerItem {
        .footerLink {
          color: var(--black);
          transition: color ease-in-out 0.2s;

          &:hover {
            color: var(--shadow);
          }
        }
      }
    }
  }

  .footerBottom {
    text-align: center;
    border-top: 1px solid var(--light);
    width: 100%;
    padding-top: 1rem;
  }

  .footerSocial {
    margin: 1rem auto 0;
    @extend .flexCol;
    @extend .alignItemsCenter;
    padding-bottom: 1.5rem;
    gap: 0.75rem;

    .footerContact {
      @extend .flexCol;

      .footerSocialItem {
        font-weight: 600;
        color: var(--shadow);
        font-size: 15px;
        margin-bottom: 0.5rem;
      }
    }

    .footerText {
      @extend .flexCol;
      @extend .alignItemsCenter;
      gap: 0.5rem;

      .footerLink {
        @extend .flex;

        gap: 0.5rem;

        .text {
          font-size: 0.85rem;
          color: var(--black);
        }

        .icon {
          color: var(--lilac);
          font-size: 1rem;
        }

        .contactDiv {
          @extend .flexCol;

          gap: 0.5rem;
        }
      }
    }

    .footerSocialLinks {
      @extend .flex;
      @extend .alignItemsCenter;
      @extend .justContentCenter;
      gap: 1rem;
      font-size: 1.5rem;
      color: #fff;
      padding-top: 0.5rem;

      .footerSocialLinkItem {
        width: 40px;
        height: 40px;
        border: 1px solid var(--lilac);
        border-radius: 50%;

        @extend .flex;
        @extend .alignItemsCenter;
        @extend .justContentCenter;

        transition: background-color ease-in-out 0.3s;

        &:hover {
          border: 1px solid var(--shadow);
        }

        .footerSocialLink {
          color: var(--lilac);
          padding-top: 0.4rem;
          transition: color ease-in-out 0.3s;

          &:hover {
            color: var(--shadow);
          }
        }
      }
    }

    @media screen and (min-width: 956px) {
      width: 85%;
      // flex-direction: column;
      justify-content: space-between;
      margin-top: 2rem;

      .footerContact {
        display: flex;
        flex-direction: column;

        .footerSocialItem {
          font-weight: 600;
          color: var(--white);
          font-size: 15px;
          margin-bottom: 0.5rem;
        }
      }
    }
  }

  @media screen and (min-width: 956px) {
    padding: 3% 5% 1%;
    width: 100%;
    gap: 2rem;
    align-items: unset;

    .footerTop {
      display: flex;
      gap: 3rem;
      // justify-content: space-between;

      .footerContactContent {
        gap: 3rem;
        justify-content: space-between;
        width: 100%;

        .footerContact {
          .footerText {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;

            .footerSocialItem {
              font-weight: 600;
              font-size: 1rem;
              color: var(--shadow);
              margin-bottom: 0.5rem;
            }      
      
            .footerLink {
              display: flex;
              align-items: center;
              gap: 0.5rem;
      
              .text {
                font-size: 0.9rem;
                color: var(--black);
              }
      
              .icon {
                font-size: 1.2rem;
                color: var(--lilac)
              }
      
              .contactDiv {
                display: flex;
                flex-direction: column;
      
                gap: 0.5rem;
              }
            }
          }
      
          .footerSocialLinks {
            display: flex;
            align-items: center;
            justify-content: start;
            gap: 1rem;
            font-size: 1rem;
            padding-top: 0.5rem;
      
            .footerSocialLinkItem {
              width: 30px;
              height: 30px;
              border: 1px solid var(--lilac);
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
              transition: background-color ease-in-out 0.3s;
      
              &:hover {
                border: 1px solid var(--shadow);
              }
      
              .footerSocialLink {
                color: var(--lilac);
                padding-top: 0.4rem;
                transition: color ease-in-out 0.3s;
      
                &:hover {
                  color: var(--shadow);
                }
              }
            }
          }
        }

        .footerContactItem {
          font-size: 1rem;
        }

        .footerContactMenu {
          .footerLink {
            font-size: 0.9rem;

            &:hover {
              color: var(--light);
            }
          }

          .footerItem {
            font-size: 0.9rem;
          }
        }
      }
      
      .footerSocial {
        margin: 0;
        width: inherit;

        .footerContact {
          .footerText {
            align-items: flex-start;
          }
        }
      }
    }
  }
}
